import { Value } from '@/lib/constants';
import { IListingItem } from '@/modules/core/types';
import { NEXT_API_URL } from 'environment';

export interface ProductTypeMap {
  DEFAULT: 0;
  NEWS: 1;
}
export interface UserTypeMap {
  STANDARD: 0;
  SHOP: 1;
}
export type UserGeolocation = {
  latitude: number;
  longitude: number;
};
export type UserLocation = {
  governorate: string;
  delegation: string;
  coordinates?: UserGeolocation;
};
export interface AdvantagesMap {
  HAS_A_PHYSICAL_LOCATION: 0;
  SCHEDULE_A_VISIT: 1;
  INVOICE: 2;
  DELIVERY_SERVICE: 3;
  ACCEPTS_CREDIT_CARDS: 4;
  GUARANTEE: 5;
  TEST_DRIVE: 6;
  MAINTENANCE: 7;
  MONTHLY_INSTALLMENTS: 8;
  INSURANCE: 9;
  LOAN: 10;
  FINANCING: 11;
}
export type Timestamp = {
  seconds: number;
  nanos: number;
};
export interface UserStateChangeTypeMap {
  FILL: 0;
  BURN: 1;
}
export type UserState = {
  timestamp?: Timestamp;
  statechangetype: UserStateChangeTypeMap[keyof UserStateChangeTypeMap];
  avatar: string;
  email: string;
  fullname: string;
  phonenumber: string;
  tokenbalance: number;
  description: string;
  advantagesList: Array<AdvantagesMap[keyof AdvantagesMap]>;
  address: string;
  categoryid: string;
  location?: UserLocation;
  url: string;
  username: string;
};
export type User = {
  kcid: string;
  creationtimestamp?: Timestamp;
  currentstate?: UserState;
  statesList: Array<UserState>;
  usertype: UserTypeMap[keyof UserTypeMap];
  shopbackgroundimage: string;
  favoritesList: Array<string>;
  enabled: boolean;
  publickey: string;
  privatekey: string;
  label: string;
  blacklistList: Array<string>;
  producttype: ProductTypeMap[keyof ProductTypeMap];
};
export type UserResponse = {
  id: string;
  user?: User;
};

export const getUser = async (userKC: Value<string>): Promise<UserResponse> => {
  const r: UserResponse = await fetch(`${NEXT_API_URL}/marketplace/get_user/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userKC,
    }),
  }).then((res) => res.json());

  return r;
};


export const getGoldenAd = async (category: Value<string>): Promise<IListingItem> => {
  const r: IListingItem = await fetch(`${NEXT_API_URL}/marketplace/golden-ad/?category=${category}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  })
    .then((res) => res.json());

  return r;
};
