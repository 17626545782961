import { useEffect } from 'react';

import { getCookie, setCookies } from 'cookies-next';
import { atom, useAtom } from 'jotai';
import { useRouter } from 'next/router';

// import { changePageQueryParamMutexKey } from '@/modules/core/hooks/useTrackAdCardViews';
import {
  ItemLayout,
  ItemSideGalleryLayout,
} from '@/modules/core/store/grpc-free-store';

import { LoadHeavyContentAtom, pageLoading, PageLoadingAction } from '../store';

const ScrollPositionAtom = atom<{ [url: string]: number }>({});
const IsBackAtom = atom(false);

export const usePreserveScroll = () => {
  const router = useRouter();

  const [, setIsLoading] = useAtom(pageLoading);

  const [scrollPositions, setScrollPositions] = useAtom(ScrollPositionAtom);
  const [isBack, setIsBack] = useAtom(IsBackAtom);

  const [, setLayout] = useAtom(ItemSideGalleryLayout);

  // const MutexRunner = useMutex();
  // const mutex = new MutexRunner(changePageQueryParamMutexKey);

  const [loadHeavyContent, setLoadHeavyContent] = useAtom(LoadHeavyContentAtom);

  useEffect(() => {
    router.beforePopState(() => {
      setIsBack(true);
      return true;
    });

    const onRouteChangeStart = () => {
      const scrollPos = window.scrollY;
      const url = `${
        router.locale !== router.defaultLocale ? `/${router.locale}` : ''
      }${router.asPath}`;
      setIsLoading(PageLoadingAction.ResetStart);
      setScrollPositions((prev) => ({
        ...prev,
        [url]: scrollPos,
      }));
      document.body.style.minHeight = `0px`;

      setLayout(ItemLayout.Normal);
    };

    const onRouteChangeComplete = (url: string | number) => {
      if (!loadHeavyContent) {
        const loadHeavyContentCookie = getCookie('load-heavy-content');
        if (!loadHeavyContentCookie) {
          setCookies('load-heavy-content', true, { sameSite: true });
          setLoadHeavyContent(true);
        }
      }

      if (isBack && scrollPositions[url]) {
        document.body.style.minHeight = `${
          (scrollPositions[url] || 0) + window.innerHeight
        }px`;
        window.scroll({
          top: scrollPositions[url] || 0,
          behavior: 'auto',
        });
        // mutex.run(async () => {
        //   mutex.lock();

        //   mutex.unlock();
        // });
      }

      setIsBack(false);

      setIsLoading(PageLoadingAction.Stop);
    };

    router.events.on('routeChangeStart', onRouteChangeStart);
    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, setIsLoading, setLayout]);
};
