export const environment = {
  production: process.env.NODE_ENV || typeof window === 'undefined',
  iam:
    (typeof window === 'undefined'
      ? process.env.IAM_URL_SSR
      : process.env.NEXT_PUBLIC_IAM_URL_CSR) || 'https://www.tayara.tn/iam',
  marketPlaceHostClient:
    (typeof window === 'undefined'
      ? process.env.MARKETPLACE_HOST_CLIENT_URL_SSR
      : process.env.NEXT_PUBLIC_MARKETPLACE_HOST_CLIENT_URL_CSR) ||
    'https://www.tayara.tn/core',
  client_secret:
    process.env.CLIENT_SECRET || '47df1b11-493c-46a0-8d89-449e86781a2c',
  client_id: process.env.CLIENT_ID || 'caravel-marketplace',
  auth_url:
    process.env.NEXT_PUBLIC_AUTH_URL ||
    'https://www.tayara.tn/auth/realms/caravel/protocol/openid-connect/token',
  imageresizeOld:
    process.env.IMAGE_RESIZE_OLD_URL ||
    'https://www.tayara.tn/mediaGateway/resize-image',
  imageresizeNew: process.env.IMAGE_RESIZE_NEW_URL || 'https://cdn.tayara.tn',
  livechat: 'wss://www.tayara.tn/socket/connection/websocket',
  allowedDomains: ['localhost:3000'],
  disallowedRoutes: ['/login'],
  messengerHost: (typeof window === 'undefined'
  ? process.env.MESSENGER_HOST_CLIENT_URL_SSR
  : process.env.NEXT_PUBLIC_MESSENGER_HOST_CLIENT_URL_CSR) || 'https://www.tayara.tn/messenger',
  BannerHost: 'https://www.tayara.tn/static',
  uploadImagesHost:
    process.env.NEXT_PUBLIC_IMAGE_UPLOAD_NEW_URL ||
    'http://localhost:3000/mediaGateway',
  google_private_key:
    process.env.GOOGLE_PRIVATE_KEY ||
    '-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCwa2p04ja+ihlL\n8LAKJdsDRzysYu7EwZ20A2Tv9TYXd3xg4bmvUZdTMe15zT5kveqH7l4xaq6vfq0m\n7aGG57dPOCtcwJPTSGLAyAvwTXIqFm9BLSr3yv4xkXiQ8rC4LBdAKyODwneOfU4C\nPHBdrd/emXkgDW1nSM0luLYtnoBk0sYh1YQgKdV+xirDhN0+K+8w2cdSUhHIzzll\nZpr3cptR0jJKpj5DzQc8Bo9k9dOeOWFFkayorLvd9xvkVPIB5yjNigRUOjUvNeBs\nBDG7W1cvhFXBZh9TyaPZoAa3LqD63VpA1sLD1C2PRwK9dq/24QyHztTWKCSZT0pV\ngdZLMIhVAgMBAAECggEAGEPN8qGikfaAoDdMnzixtPO5XqSE3r8ap8XHvlFlzTh7\n/P9PANQiEilN1nDqYgTVGzkn73gS6sRroG0SyHFafyTkoe9C5c6Q9p8NNVIRn8RS\nLQ6/WDGtYLf3c0hOEibOU98bFWZL92c4sYoKknf10LIfPM9b+tbCkKulMwUX98Xj\nJUFXTyUfJk802ziCOh4bWlbPPBqAb0Z93+SlDa/+upDbss2to7yFrGpJWrmM2nzJ\nMeEa764tCoi7vQwODO/W4wX9oOGuAYvUXYMqg2mULMryPzScm9tcKPkN05NutFBu\nA55pTZDK7x4/ZCiHCGbJZUEzuN4a105u6hBZFSTGiQKBgQDlVnYfHJethn05/t6O\nJoQraKEZfdmU3jQv5AnBZIsRs0UJdiDSY/jpCC4fbrqR6SaMkZh4ga3Y08G9vZlz\n2URgepyAIonK/eHBRfjnOs23jYqhHItNKET5ISC350onfgJBl6OHu936P5LGHQfz\n8Fnhy8FuvPPEZwwYpJa8SSqVZwKBgQDE7gHT/QbQ6Sp2WdNsnGOBbSpgnLM0VOjv\nTzFtaovZhZImgs2LpHep2TFkvlBmAHVGdKHgzbr9YApcrkOrWAK5P06DX8Yk6wOj\nSQvqRABQyHRO93mj+lRxV7tEOrLSq/fT/NrvttkMLaWf5wm1lKXm21UcMvCthMHo\n6l1aCVrC4wKBgCH0TiLHEfHgEpROltbG/xSKNifXCSrQRzu78HHQmAJz30nKUouz\nv3uI+YMv2rGVql5vg/p7gKi0mV+lPZ34UfZS0O7rrij+s7jwyiw5+ZEuhJLb7gfr\naWLVM8nmlYUJU/4wHEcRWokddnacf7vvHu+eQjJKCXFPrGvNmn+f+Y0LAoGBAITq\nAy/aQmR0SyWQIQPYVczAKm8EtGsouAL1F247EGLjJS568FJE0kNABXdyf/8oZf8E\nr1F0abWjwbqmH6QhBc2ULeC7npfCSzVFExatiOz9LosxutbzGVVamllFqPZJ/T4G\nFpredYYjtPrEzCfTSVoURVCADTOAPASBUjp3v9tzAoGBALxIabvcJ7bqWu8Fxhlq\n61ISbtDAA2pqDj7Il9yEiiml8+sYj4rcxHaWQeSHnQLeuggfYfSsx+BlgJ0IbaSL\n9XRnrAISGotQjZl/2+vTWa8GYQxsLW4lHr9Hmbpsy1Pfz5gBZ4ymYRS4UsOlwXhH\nKR6AeotSdmJ+PkB8tNOVlZBt\n-----END PRIVATE KEY-----\n',
  google_client_email:
    process.env.GOOGLE_CLIENT_EMAIL ||
    'sheets@tayara-tn-319608.iam.gserviceaccount.com',
  apiKey: 'AIzaSyDTMetvO4z4Qj3gjdDqPV398NLFmTXONsQ',
  authDomain: 'tayara-tn-319608.firebaseapp.com',
  databaseURL:
    'https://tayara-tn-319608-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'tayara-tn-319608',
  storageBucket: 'tayara-tn-319608.appspot.com',
  messagingSenderId: '137150767366',
  appId: '1:137150767366:web:7a3cd9cf2034d3178d3e87',
  measurementId: 'G-RYTZ92YPV4',
  vapidKey:
    'BMldrBiuWfaKwfhgGId0_WJfJ79_FELyydRRFC2AKyNZFgY38BtDtAKQ-89taABx2asNAgH0cShCGspL7OUa0CI',
  AuthorizerURL: 'https://authentication.tayara.tn',
  jwksUri:
    'https://www.tayara.tn/auth/realms/caravel/protocol/openid-connect/certs',
  strapiUri: 'https://strapi-api.tayara.tn/api',
  strapiToken:
    'Bearer 997f0948b7c4995390f4f6d9d0591b396df4a85c4c327a98eba5d3b2ed8667a9e17f7866798b827b925f332154569b1d0a2c2df4dbaac947f6d40024171d4567b9df700823f18c1cb1229990f1e5b4eef908ba99dfc82c8eb8eac7684ec72b2279cef3987422f4f989346632da43fac99a06632483ccfd58bc211876d52f462e',
  elasticSearchUrl:
    (typeof window === 'undefined'
      ? process.env.ELASTICSEARCH_HOST_CLIENT_URL_SSR
      : process.env.NEXT_PUBLIC_ELASTICSEARCH_HOST_CLIENT_URL_CSR) ||
    'https://tayara-search.tayara.tn',
  saveSearchUrl:
    (typeof window === 'undefined'
      ? process.env.SAVE_SEARCH_HOST_CLIENT_URL_SSR
      : process.env.NEXT_PUBLIC_SAVE_SEARCH_HOST_CLIENT_URL_CSR) ||
    'https://save-search.tayara.tn',
};

export const NEXT_API_URL =
  typeof window === 'undefined' ? 'http://localhost:3000/api' : '/api';
