/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo } from 'react';

import { useAtom } from 'jotai';
import jwt_decode from 'jwt-decode';
import { isEmpty } from 'lodash-es';
import { useLocalStorage } from 'usehooks-ts';

import { refresh } from '@/api/fetch/auth-service';
import { getUser } from '@/api/fetch/rest-marketplace-api';
import { RefreshTokenRequest } from '@/api/gen-protos/auth_pb';
import { User } from '@/models/user';
import {
  AuthTokenAtom,
  UserDataAtom,
} from '@/modules/core/store/grpc-free-store';

import { Value } from '../constants';
import { pageLoading, PageLoadingAction } from '../store';

function useAuth() {
  const [, setAuthToken] = useAtom(AuthTokenAtom);

  // this atom is used to save user data
  //  no more user data in localstorage !
  const [userData, setUserData] = useAtom(UserDataAtom);
  const [expiredIn, setExpires_in] = useLocalStorage<Value<number>>(
    'expires_in',
    null
  );
  const [refresh_expires_in, setRefresh_expires_in] = useLocalStorage<
    Value<number>
  >('refresh_expires_in', null);
  const [, setIsLoading] = useAtom(pageLoading);

  const [refresh_token, setRefresh_token] = useLocalStorage<Value<string>>(
    'refresh_token',
    null
  );
  const getUserData = async (kcid: string, username: string) => {
    const iserRes = await getUser(kcid);

    const userFormat: User = {
      avatar: iserRes.user?.currentstate?.avatar,
      kcid: iserRes.user?.kcid,
      productType: iserRes.user?.producttype,
      userType: iserRes.user?.usertype,
      phonenumber: iserRes.user?.currentstate?.phonenumber,
      shopBackground: iserRes.user?.shopbackgroundimage,
      fullname: iserRes.user?.currentstate?.fullname,
      email: iserRes.user?.currentstate?.email,
      username,
    };

    if (userFormat) {
      setUserData({ ...userFormat });
    }
  };

  const isLogged = useMemo(
    () =>
      (userData.isPopulated || !isEmpty(userData)) &&
      expiredIn &&
      Date.now() / 1000 < +expiredIn,

    [expiredIn, userData]
  );

  /* const refrechToken = () => {
    if (Date.now() / 1000 + 120 >= +(refresh_expires_in || 0)) {
      const { client_secret, client_id } = environment;

      const params =
        `refresh_token=${refresh_token}&` +
        `grant_type=refresh_token&` +
        `client_id=${client_id}&` +
        `client_secret=${client_secret}`;

      const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
      fetch(environment.auth_url, {
        method: 'POST',
        body: params,
        headers,
      })
        .then((res) => res.json())
        .then((res) => {
          setRefresh_token(res.refresh_token);
          setExpires_in(Date.now() / 1000 + res.expires_in);

          setRefresh_expires_in(Date.now() / 1000 + res.refresh_expires_in);
        });
    }
  };
*/
  const refrechToken = () => {
    if (Date.now() / 1000 + 120 >= +(refresh_expires_in || 0)) {
      const refreshTokenRequest = new RefreshTokenRequest();
      refreshTokenRequest.setRefreshToken(refresh_token || '');
      refresh(refreshTokenRequest).then((refreshResult) => {
        setRefresh_token(refreshResult.getRefreshToken());
        setExpires_in(refreshResult.getExpiresIn());
        setAuthToken(refreshResult.getToken());
        setRefresh_expires_in(refreshResult.getRefreshExpiresIn());
      });
    }
  };
  const populateAtomWithUserData = () => {
    if (localStorage.getItem('auth_token')) {
      setIsLoading(PageLoadingAction.Start);
      setAuthToken(localStorage.getItem('auth_token') || '');

      const claims: any = jwt_decode(localStorage.getItem('auth_token') || '');
      if (Date.now() / 1000 > +claims.exp) {
        refrechToken();
      }
      getUserData(
        claims.sub?.kcid || claims?.sub || '',
        claims.sub?.currentstate?.username || claims?.preferred_username || ''
      );
      setIsLoading(PageLoadingAction.Stop);
    }
  };

  const logout = () => {
    setUserData({});
    setExpires_in(null);
    setAuthToken('');
    setRefresh_expires_in(null);
  };

  return { logout, refrechToken, isLogged, populateAtomWithUserData };
}

export default useAuth;
