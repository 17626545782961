import { grpc } from '@improbable-eng/grpc-web';
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport';

import {
  ChangePasswordRequestGRPC,
  ChangePasswordResponseGRPC,
  DeleteUserDataRequest,
  DeleteUserDataResponse,
  GetUserRequestGrpc,
  GetUserResponseGrpc,
  LoginRequest,
  LoginResult,
  RefreshTokenRequest,
  SendOtpRequest,
  SendOtpResponseGRPC,
  SignupRequest,
  SignupResponse,
} from '@/api/gen-protos/auth_pb';
import { auth } from '@/api/gen-protos/auth_pb_service';
import { Value } from '@/lib/constants';
import { environment } from 'environment';

export interface LoginStruct {
  error: any;
  access_token: Value<string>;
  refresh_token: Value<string>;
  expires_in: Value<number>;
  refresh_expires_in: Value<number>;
}
export const login = (loginRequest: LoginRequest): Promise<LoginStruct> =>
  new Promise<LoginStruct>((resolve, reject) => {
    let loginResult: LoginResult;
    grpc.invoke(auth.login, {
      request: loginRequest,
      host: environment.AuthorizerURL,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            mode: 'cors',
          }),

      onMessage: (response: LoginResult) => {
        loginResult = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          const struct: LoginStruct = {
            refresh_token: loginResult?.getRefreshToken().toString() || '',
            access_token: loginResult?.getToken().toString() || '',
            error: null,
            expires_in: loginResult?.getExpiresIn() || 0,
            refresh_expires_in: loginResult?.getRefreshExpiresIn() || 0,
          };
          resolve(struct);
          localStorage.setItem('newToken', String(true));
        } else {
          reject(msg);
        }
      },
    });
  });
export const refresh = (
  refreshRequest: RefreshTokenRequest
): Promise<LoginResult> =>
  new Promise<LoginResult>((resolve, reject) => {
    let refreshResponse: LoginResult;
    grpc.invoke(auth.refresh_token, {
      request: refreshRequest,
      host: environment.AuthorizerURL,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            mode: 'cors',
          }),

      onMessage: (response: LoginResult) => {
        refreshResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(refreshResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
export const SendOtp = (
  sendOtpRequest: SendOtpRequest
): Promise<SendOtpResponseGRPC> =>
  new Promise<SendOtpResponseGRPC>((resolve, reject) => {
    let sendOtpResponse: SendOtpResponseGRPC;
    grpc.invoke(auth.send_otp, {
      request: sendOtpRequest,
      host: environment.AuthorizerURL,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            mode: 'cors',
          }),

      onMessage: (response: SendOtpResponseGRPC) => {
        sendOtpResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(sendOtpResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
export const VerifyOtpService = (
  verifyOtpRequest: SendOtpRequest
): Promise<SendOtpResponseGRPC> =>
  new Promise<SendOtpResponseGRPC>((resolve, reject) => {
    let verifyOtpResponse: SendOtpResponseGRPC;
    grpc.invoke(auth.verify_otp, {
      request: verifyOtpRequest,
      host: environment.AuthorizerURL,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            mode: 'cors',
          }),

      onMessage: (response: SendOtpResponseGRPC) => {
        verifyOtpResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(verifyOtpResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
export const ChangePasswordService = (
  changePasswordRequest: ChangePasswordRequestGRPC
): Promise<ChangePasswordResponseGRPC> =>
  new Promise<ChangePasswordResponseGRPC>((resolve, reject) => {
    let changePassowrdResponse: ChangePasswordResponseGRPC;
    grpc.invoke(auth.change_password, {
      request: changePasswordRequest,
      host: environment.AuthorizerURL,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            mode: 'cors',
          }),

      onMessage: (response: ChangePasswordResponseGRPC) => {
        changePassowrdResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(changePassowrdResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
export const SignupService = (
  signupRequest: SignupRequest
): Promise<SignupResponse> =>
  new Promise<SignupResponse>((resolve, reject) => {
    let signupResponse: SignupResponse;
    grpc.invoke(auth.sign_up, {
      request: signupRequest,
      host: environment.AuthorizerURL,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            mode: 'cors',
          }),

      onMessage: (response: SignupResponse) => {
        signupResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(signupResponse);
        } else {
          reject(msg);
        }
      },
    });
  });

export const GetUser = (
  getUserRequest: GetUserRequestGrpc
): Promise<GetUserResponseGrpc> =>
  new Promise<GetUserResponseGrpc>((resolve, reject) => {
    let userResponse: GetUserResponseGrpc;
    grpc.invoke(auth.get_user, {
      request: getUserRequest,
      host: environment.AuthorizerURL,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
              keepalive: true,
              mode: 'cors',
            }),

      onMessage: (response: GetUserResponseGrpc) => {
        userResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(userResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
export const DeleteAccount = (
  req: DeleteUserDataRequest
): Promise<DeleteUserDataResponse> =>
  new Promise<DeleteUserDataResponse>((resolve, reject) => {
    let res: DeleteUserDataResponse;
    grpc.invoke(auth.delete_user_account, {
      request: req,
      host: environment.AuthorizerURL,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            mode: 'cors',
          }),

      onMessage: (response: DeleteUserDataResponse) => {
        res = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(res);
        } else {
          reject(msg);
        }
      },
    });
  });
