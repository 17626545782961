import { atom } from 'jotai';

import { SelectedRating } from '@/modules/core/components/rating/types';

// here global atom
const localLoadHeavyContent = atom(
  typeof window !== 'undefined' &&
    window?.localStorage?.getItem('load-heavy-content') === 'true'
);
export const LoadHeavyContentAtom = atom(
  (get) => get(localLoadHeavyContent),
  (_get, set, newState: boolean) => {
    set(localLoadHeavyContent, !!newState);
    localStorage.setItem('load-heavy-content', newState ? 'true' : 'false');
  }
);

export const ForgetPasswordIdentifiantAtom = atom<any>({});
export const AuthErrorsAtom = atom<any>({});
export const RatingAtom = atom({
  open: false,
  selectedRating: SelectedRating.None,
});

export enum PageLoadingAction {
  Start,
  Stop,
  Reset,
  ResetStart,
}

const pageLoadingState = atom(1);
export const pageLoading = atom(
  (get) => get(pageLoadingState) > 0,
  (get, set, action: PageLoadingAction) => {
    const currentState = get(pageLoadingState);
    switch (action) {
      case PageLoadingAction.Start:
        set(pageLoadingState, currentState + 1);
        break;
      case PageLoadingAction.Stop:
        set(pageLoadingState, currentState <= 1 ? 0 : currentState - 1);
        break;
      case PageLoadingAction.ResetStart:
        set(pageLoadingState, 1);
        break;
      case PageLoadingAction.Reset:
        set(pageLoadingState, 0);
        break;
      default:
        break;
    }
  }
);

// below atom used to format succes / error message

export type TabError = {
  [pageName: string]: string;
};

const formatedError = atom<TabError>({});

export type TabErrorAction = {
  type: 'set' | 'init';

  tabName: string;
  message: string;
};
export const ErorrsAtom = atom(
  (get) => get(formatedError),
  (_, set, action: TabErrorAction) =>
    action.type === 'init'
      ? set(formatedError, {})
      : set(formatedError, (prev) => ({
          ...prev,
          [action.tabName]: action.message,
        }))
);

export type TabSucces = {
  [pageName: string]: string;
};

const formatedSucces = atom<TabSucces>({});

export type TabSuccessAction = {
  type: 'set' | 'init';
  tabName: string;
  message: string;
};
export const SuccessAtom = atom(
  (get) => get(formatedSucces),
  (_, set, action: TabSuccessAction) =>
    action.type === 'init'
      ? set(formatedSucces, {})
      : set(formatedSucces, (prev) => ({
          ...prev,
          [action.tabName]: action.message,
        }))
);

export const stickyBannerAtom = atom(false);

export const isTransitionningAtom = atom<boolean>(false);
