import React, { ReactNode } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

type TBreadCrumbProps = {
  homeElement: ReactNode;
  separator: ReactNode;
  containerClasses?: string;
  listClasses?: string;
  activeClasses?: string;
  capitalizeLinks?: boolean;
};

const NextBreadcrumb = ({
  homeElement,
  separator,
  containerClasses,
  listClasses,
  activeClasses,
  capitalizeLinks,
}: TBreadCrumbProps) => {
  const router = useRouter();
  const pathNames = router.asPath
    .split('/')
    .filter((path) => path)
    .map((path) => decodeURIComponent(path.replace(/[+&=]/g, ' ')));
  return (
    <>
      {router.pathname !== '/' &&
        router.pathname !== '/leshowdelautomobile' &&
        !router.pathname.includes('immoNeuf') && (
          <div>
            <ul className={containerClasses}>
              <li className={listClasses}>
                <Link href={'/'}>{homeElement}</Link>
              </li>
              {pathNames.length > 0 && separator}
              {pathNames.map((link, index) => {
                const href = `/${pathNames.slice(0, index + 1).join('/')}`;
                const itemClasses =
                  router.asPath === href
                    ? `${listClasses} ${activeClasses}`
                    : listClasses;

                const queryParamIndex = link.indexOf('=');
                if (queryParamIndex !== -1) {
                  const paramKey = link.slice(0, queryParamIndex);
                  const paramValue = link.slice(queryParamIndex + 1);

                  return (
                    <React.Fragment key={index}>
                      <li className={itemClasses}>
                        <span>{paramKey.replace('?', '')}</span>
                      </li>
                      {separator}
                      <li className={itemClasses}>
                        <span>{paramValue}</span>
                      </li>
                      {pathNames.length !== index + 1 && separator}
                    </React.Fragment>
                  );
                }

                let itemLink = capitalizeLinks
                  ? link[0]?.toUpperCase() + link.slice(1)
                  : link;

                if (itemLink === 'K') {
                  itemLink = 'Recherche';
                }
                if (itemLink === 'C') {
                  itemLink = 'Catégorie';
                }
                if (itemLink === 'T') {
                  itemLink = 'Etat de produit';
                }
                if (itemLink === 'L') {
                  itemLink = 'Emplacement';
                }
                if (itemLink === 'Pushed') {
                  return null;
                }
                if (itemLink === 'Item') {
                  return null;
                }
                if (itemLink.length > 140) {
                  itemLink = `${itemLink.substring(0, 140)} ...`;
                }
                if (/^[0-9a-fA-F]{24}$/.test(itemLink)) {
                  return null;
                }

                return (
                  <React.Fragment key={index}>
                    <li className={itemClasses}>
                      <span>
                        {itemLink
                          .replace('?', '')
                          .replace('_', ' ')
                          .replace('_', ' ')}
                      </span>
                    </li>

                    {pathNames.length !== index + 1 && separator}
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        )}
    </>
  );
};

export default NextBreadcrumb;
