// package: Auth
// file: auth.proto

var auth_pb = require("./auth_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var auth = (function () {
  function auth() {}
  auth.serviceName = "Auth.auth";
  return auth;
}());

auth.sign_up = {
  methodName: "sign_up",
  service: auth,
  requestStream: false,
  responseStream: false,
  requestType: auth_pb.SignupRequest,
  responseType: auth_pb.SignupResponse
};

auth.login = {
  methodName: "login",
  service: auth,
  requestStream: false,
  responseStream: false,
  requestType: auth_pb.LoginRequest,
  responseType: auth_pb.LoginResult
};

auth.change_password = {
  methodName: "change_password",
  service: auth,
  requestStream: false,
  responseStream: false,
  requestType: auth_pb.ChangePasswordRequestGRPC,
  responseType: auth_pb.ChangePasswordResponseGRPC
};

auth.send_otp = {
  methodName: "send_otp",
  service: auth,
  requestStream: false,
  responseStream: false,
  requestType: auth_pb.SendOtpRequest,
  responseType: auth_pb.SendOtpResponseGRPC
};

auth.verify_otp = {
  methodName: "verify_otp",
  service: auth,
  requestStream: false,
  responseStream: false,
  requestType: auth_pb.VerifyOtpRequestGRPC,
  responseType: auth_pb.SendOtpResponseGRPC
};

auth.update_user_status = {
  methodName: "update_user_status",
  service: auth,
  requestStream: false,
  responseStream: false,
  requestType: auth_pb.UpdateUserStatusRequestGRPC,
  responseType: auth_pb.UpdateUserStatusResponseGRPC
};

auth.impersonate_user = {
  methodName: "impersonate_user",
  service: auth,
  requestStream: false,
  responseStream: false,
  requestType: auth_pb.ImpersonateUserRequestGRPC,
  responseType: auth_pb.ImpersonateUserResponseGRPC
};

auth.refresh_token = {
  methodName: "refresh_token",
  service: auth,
  requestStream: false,
  responseStream: false,
  requestType: auth_pb.RefreshTokenRequest,
  responseType: auth_pb.LoginResult
};

auth.get_user = {
  methodName: "get_user",
  service: auth,
  requestStream: false,
  responseStream: false,
  requestType: auth_pb.GetUserRequestGrpc,
  responseType: auth_pb.GetUserResponseGrpc
};

auth.delete_user_account = {
  methodName: "delete_user_account",
  service: auth,
  requestStream: false,
  responseStream: false,
  requestType: auth_pb.DeleteUserDataRequest,
  responseType: auth_pb.DeleteUserDataResponse
};

exports.auth = auth;

function authClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

authClient.prototype.sign_up = function sign_up(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(auth.sign_up, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

authClient.prototype.login = function login(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(auth.login, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

authClient.prototype.change_password = function change_password(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(auth.change_password, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

authClient.prototype.send_otp = function send_otp(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(auth.send_otp, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

authClient.prototype.verify_otp = function verify_otp(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(auth.verify_otp, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

authClient.prototype.update_user_status = function update_user_status(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(auth.update_user_status, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

authClient.prototype.impersonate_user = function impersonate_user(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(auth.impersonate_user, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

authClient.prototype.refresh_token = function refresh_token(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(auth.refresh_token, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

authClient.prototype.get_user = function get_user(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(auth.get_user, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

authClient.prototype.delete_user_account = function delete_user_account(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(auth.delete_user_account, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.authClient = authClient;

