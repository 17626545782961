import { useEffect } from 'react';

import { useAtom } from 'jotai';
import { Router } from 'next/router';

import { isTransitionningAtom } from '../store';

export default function useGoogleAdsPageTransition() {
  const [, setIsTransitioning] = useAtom(isTransitionningAtom);

  useEffect(() => {
    const setTransitionStarted = () => {
      setIsTransitioning(true);

      // destroy all ad slots
      const { googletag } = window;
      googletag.cmd.push(() => {
        googletag.destroySlots();
      });
    };

    const setTransitionComplete = () => {
      setIsTransitioning(false);
    };

    Router.events.on('routeChangeStart', setTransitionStarted);
    Router.events.on('routeChangeComplete', setTransitionComplete);

    return () => {
      Router.events.off('routeChangeStart', setTransitionStarted);
      Router.events.off('routeChangeComplete', setTransitionComplete);
    };
  }, [setIsTransitioning]);
}
