import { atom } from 'jotai';

import { User } from '@/models/user';

export enum ItemLayout {
  Normal,
  Wide,
}

export const ItemSideGalleryLayout = atom<ItemLayout>(ItemLayout.Normal);

const initAuthTokenAtom = atom<string>('');
export const AuthTokenAtom = atom(
  (get) => {
    const authToken = get(initAuthTokenAtom);
    if (authToken === '' && typeof window !== 'undefined') {
      return localStorage.getItem('auth_token') || '';
    }
    return authToken;
  },
  (_, set, data: string) => {
    localStorage.setItem('auth_token', data);
    set(initAuthTokenAtom, data);
  }
);

export interface UserAtom extends User {
  isPopulated?: boolean;
}

export const userAtom = atom<UserAtom>({ isPopulated: true });

export const UserDataAtom = atom(
  (get) => get(userAtom),
  (_, set, userDataAction: UserAtom) => set(userAtom, userDataAction)
);
